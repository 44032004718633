<template>
  <div>
    <v-dialog v-model="addHolidayDialog" persistent width="450px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text">Add Holiday </v-toolbar-title>
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-form ref="form">
          <v-row no-gutters class="mt-3 mx-3">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
              color="#f4813f"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  class="white--text"
                  :value="computedDateFormattedMomentjs"
                  label="Select Date *"
                  readonly
                  color="#f4813f"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                color="#f4813f"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-row>
          <v-row class="mx-3" no-gutters>
            <v-text-field
              v-model="occasion"
              color="primary"
              label="Occasion *"
              :rules="occassion_rules"
              required
              outlined
              dense
            ></v-text-field>
          </v-row>
        </v-form>
        <v-row>
          <v-card-text align="center">
            <v-btn
              :disabled="!formisvalid"
              color="#D97D54"
              class="gradient-bg white--text"
              :loading="isLoading"
              @click="validate_form()"
              ><v-icon class="mr-2 ml-n2">mdi-plus</v-icon>
              Add Holiday
            </v-btn>
          </v-card-text>
        </v-row>
        <v-card-actions />
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import moment from "moment";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { CreateHolidayList } from "@/graphql/mutations.js";

export default {
  props: {
    addHolidayDialog: Boolean,
  },
  components: {
    SnackBar,
  },
  data: () => ({
    SnackBarComponent: {},
    isLoading: false,
    menu1: false,
    occasion: "",
    date: format(
      parseISO(new Date().toISOString().substr(0, 10)),
      "yyyy-MM-dd"
    ),
    occassion_rules: [
      (v) =>
        /^[a-z\sA-Z]{3,30}$/.test(v) ||
        "Occassion must be between 3 & 30 characters",
    ],
  }),
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("dddd, MMMM Do YYYY") : "";
    },
    formisvalid() {
      return this.date !== "" && this.occasion !== "";
    },
  },
  watch: {
    addHolidayDialog(val) {
      if (val == true) {
        this.date = new Date().toISOString().substr(0, 10);
      }
      this.newDate = val;
      if (new Date(val).getDay() == 0) {
        this.Day = "Sunday";
      } else if (new Date(val).getDay() == 1) {
        this.Day = "Monday";
      } else if (new Date(val).getDay() == 2) {
        this.Day = "Tuesday";
      } else if (new Date(val).getDay() == 3) {
        this.Day = "Wednesday";
      } else if (new Date(val).getDay() == 4) {
        this.Day = "Thursday";
      } else if (new Date(val).getDay() == 5) {
        this.Day = "Friday";
      } else if (new Date(val).getDay() == 6) {
        this.Day = "Saturday";
      }
    },
    date(val) {
      this.newDate = val;
      if (new Date(val).getDay() == 0) {
        this.Day = "Sunday";
      } else if (new Date(val).getDay() == 1) {
        this.Day = "Monday";
      } else if (new Date(val).getDay() == 2) {
        this.Day = "Tuesday";
      } else if (new Date(val).getDay() == 3) {
        this.Day = "Wednesday";
      } else if (new Date(val).getDay() == 4) {
        this.Day = "Thursday";
      } else if (new Date(val).getDay() == 5) {
        this.Day = "Friday";
      } else if (new Date(val).getDay() == 6) {
        this.Day = "Saturday";
      }
    },
  },

  methods: {
    validate_form() {
      if (this.$refs.form.validate()) {
        this.add_holiday();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Valid Inputs",
        };
      }
    },
    async add_holiday() {
      try {
        await API.graphql(
          graphqlOperation(CreateHolidayList, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              holiday_date: this.date,
              holiday_day: this.Day,
              occasion_type: this.occasion,
            },
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.CreateHolidayList.Message,
          };
          this.close_dialog();
        });
      } catch (err) {
        console.log(err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
      }
    },
    close_dialog() {
      this.occasion = "";
      this.date = "";
      this.$emit("close_add_holiday_dialog");
    },
  },
};
</script>

<style>
</style>